import * as React from "react";
import type { HeadFC } from "gatsby";
import LandingPage from "./landing";

const IndexPage = () => {
  return <LandingPage />;
};

export default IndexPage;

export const Head: HeadFC = () => <title>BitLot.app</title>;
